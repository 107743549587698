._2yXKQq88QsjKcbG4bivKny {
  --x-center: 0;
  --y-center: 0;
  --translation-x: 0;
  --translation-y: 0;
  --rotation: 0;
  --width: 0;
  --height: 0;
  --font-size: 16px;
  --color: black;

  position: absolute;
  width: var(--width);
  height: var(--height);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-weight: 300;
  font-size: var(--font-size);
  color: var(--color);
  -webkit-transition: color var(--duration) ease-in-out;
  transition: color var(--duration) ease-in-out;
  -webkit-transform:
    translateX(calc(var(--translation-x) + var(--x-center) - 50%))
    translateY(calc(var(--translation-y) + var(--y-center) - 50%))
    rotate(var(--rotation));
          transform:
    translateX(calc(var(--translation-x) + var(--x-center) - 50%))
    translateY(calc(var(--translation-y) + var(--y-center) - 50%))
    rotate(var(--rotation));
}
