._3BBOtWUHDyUOJhnGqbwlQD {
  --duration: 350ms;

  opacity: 0;
  -webkit-transition: opacity var(--duration), fill var(--duration), -webkit-transform var(--duration) ease-in-out;
  transition: opacity var(--duration), fill var(--duration), -webkit-transform var(--duration) ease-in-out;
  transition: transform var(--duration) ease-in-out, opacity var(--duration), fill var(--duration);
  transition: transform var(--duration) ease-in-out, opacity var(--duration), fill var(--duration), -webkit-transform var(--duration) ease-in-out;
  cursor: pointer;
  stroke-width: 1;
  stroke: rgba(130, 130, 150, 1)
}

._3BBOtWUHDyUOJhnGqbwlQD:hover {
  stroke: rgb(50, 70, 100);
  stroke-width: 3;
}

._2ew3Ioz5k-uRuYvt8Tbg-x {
  cursor: auto;
  stroke-width: 3;
  stroke: rgb(50, 70, 100);
}
